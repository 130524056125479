import { BASE_URL, AuthHeaders } from "./config";
import axios from "axios";

export const getBlogEntries = async (data) => {
    const response = await axios.post(
        `${BASE_URL}/blog/getAll`,
        {
            ...data
        },
        {
            headers: {
                ...AuthHeaders()
            }
        }
    );

    return response.data;
};

export const addBlogEntry = async (data) => {
    const response = await axios.post(
        `${BASE_URL}/blog/add`,
        {
            ...data
        },
        {
            headers: {
                ...AuthHeaders()
            }
        }
    );

    return response.data;
};

export const updateBlogEntry = async (data) => {
    const response = await axios.put(
        `${BASE_URL}/blog/update`,
        {
            ...data
        },
        {
            headers: {
                ...AuthHeaders()
            }
        }
    );

    return response.data;
};

export const getBlogOwners = async (data) => {
    const response = await axios.get(
        `${BASE_URL}/blog/getOwners?searchText=${data.value}`,
        {
            headers: {
                ...AuthHeaders()
            }
        }
    );

    return response.data;
};