import React, {useCallback, useEffect, useState} from "react";
import Autosuggest from "react-autosuggest";
import {debounce, isEmpty} from "lodash";
import {getBlogEntries, getBlogOwners} from "../../../store/actions/Echelon.actions";
import {useDispatch, useSelector} from "react-redux";
import Select from 'react-select';
import './SearchContainer.css';
import {lookup} from "../../../store/actions/Lookup.actions";
import {SolIconBubble, SolTextField} from "@solstice/sol-react";
import moment from "moment/moment";
import DatePicker from "react-datepicker";

const SearchContainerDesktop = (props) => {
    const [blogTextFilter, setBlogTextFilter] = useState('');
    const [blogDateFilter, setBlogDateFilter] = useState('');
    const [ownerFilter, setOwnerFilter] = useState('');
    const [submarketFilter, setSubMarketFilter] = useState([]);
    const [ownerSuggestions, setOwnerSuggestions] = useState([]);
    const [tim, setTim] = useState(false);
    const [jllOnly, setJllOnly] = useState(false);
    const dispatch = useDispatch();
    const blogOwners = useSelector(state => state.echelon.blogOwners);
    const submarkets = useSelector(state => state.lookup.lookup);
    const blogEntries = useSelector(state => state.echelon.blogEntries);

    const [submarketSuggestions, setSubmarketSuggestions] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [activeItem, setActiveItem] = useState('null');


    React.useEffect(() => {
        dispatch(lookup('GetBlogSubMarkets'));
        // addEventListener('click',() => {
        //     setActiveItem('null');
        //
        // })
    }, []);

    React.useEffect(() => {
        let tempSubmarkets = [];
        tempSubmarkets = submarkets.map(item => {
            return {label: item.value, ...item}
        });
        setSubmarketSuggestions(tempSubmarkets)
    }, [submarkets])

    useEffect(() => {
        if (!isEmpty(blogOwners)) {
            if (blogOwners.length === 1 && ownerSuggestions.length === 0 && ownerFilter === blogOwners[0].userName) {
                //Do Nothing
            } else {
                setOwnerSuggestions(blogOwners);
            }
        }
    }, [blogOwners])

    const getUsersDebounced = useCallback(
        debounce((ownerValue, usersActions) => {
            dispatch(getBlogOwners({value: ownerValue}))
        }, 300), []
    )

    React.useEffect(() => {
        const {usersActions, ownerSuggestions} = props
        if (!isEmpty(ownerFilter) && ownerFilter.length >= 3) {
            getUsersDebounced(ownerFilter, usersActions);
        }

    }, [ownerFilter]);

    const getOwnerSuggestions = value => {
        if (!isEmpty(value) && value.length >= 3) {
            const inputValue = value.trim().toLowerCase();
            const inputLength = inputValue.length;

            return inputLength === 0 && !isEmpty(ownerSuggestions) ? [] : ownerSuggestions.filter(o => {
                return o.userName.toLowerCase().slice(0, inputLength) === inputValue
            });
        } else {
            return [];
        }
    };

    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };

    const onOwnerChange = (event, {newValue}) => {
        setOwnerFilter(newValue);
    };
    const onOwnerSuggestionsFetchRequested = ({value}) => {
        const suggestions = getOwnerSuggestions(value);
        setOwnerSuggestions(suggestions);
    };
    const onOwnerSuggestionsClearRequested = () => {
        const suggestions = getOwnerSuggestions([]);
        setOwnerSuggestions(suggestions);
    };
    const onOwnerSuggestionSelected = (event, {suggestion, suggestionValue, index, method}) => {
        event.preventDefault();
        setOwnerFilter(suggestion.userName);
        onOwnerSuggestionsClearRequested();
    }

    const getOwnerSuggestionValue = suggestion => suggestion.userName;
    const renderOwnerSuggestion = suggestion => (
        <div className="dvSuggest">
            {suggestion.userName}
        </div>
    );
    const inputProps = {
        placeholder: 'Select Owner',
        value: ownerFilter,
        onChange: onOwnerChange,
    };

    const renderInputComponent = inputProps => (
        <div>
            <input {...inputProps} />
            <sol-icon
                size="24"
                style={{display: !ownerFilter && 'none', float: 'right', margin: '-32px 5px 0px 0px'}}
                icon="close"
                onClick={() => {
                    setOwnerFilter('');
                }}
            />
        </div>
    );
    return (
        <div>
            <div className={'filterContainer'}>
                <div className={activeItem === 'blogText' ? 'searchInputContainer' : 'searchInputContainerNoOutline'}>
                    <label htmlFor="owner" id="owner" className='searchLabel'>Blog Text</label>
                    <div>
                        <input
                            onClick={() => {
                                setActiveItem('blogText');
                            }}
                            placeholder={'Enter Text'}
                            className={'blogSearchInput'}
                            value={blogTextFilter}
                            onChange={e => {
                                setBlogTextFilter(e.target.value);

                            }}
                        />
                    </div>
                </div>
                <div className={activeItem === 'submarket' ? 'searchInputContainer' : 'searchInputContainerNoOutline'}
                     onClick={() => {
                         setActiveItem('submarket');
                     }}>
                    <label className='searchLabel'>Sub Market</label>

                    <Select
                        id={'submarket'}
                        isMulti
                        closeMenuOnSelect={false}
                        name="submarket"
                        value={submarketFilter}
                        options={submarketSuggestions}
                        onChange={(val) => {
                            setSubMarketFilter(val)
                        }}
                        placeholder={'Select Sub Market'}
                        className="searchSelect"
                        classNamePrefix="select"
                    />
                </div>
                <div className={activeItem === 'owner' ? 'searchInputContainer' : 'searchInputContainerNoOutline'}
                     onClick={() => {
                         setActiveItem('owner');
                     }}><label htmlFor="owner" id="owner" className='searchLabel'>Owner</label>
                    <Autosuggest
                        theme={{
                            input: ownerFilter ? "auto-suggest-input-padding" : "auto-suggest-search-input",
                            suggestionsContainer: 'auto-suggest-search-container',
                            suggestion: 'auto-suggest-suggestion',
                            suggestionHighlighted: 'suggestion-highlighted'
                        }}
                        renderInputComponent={renderInputComponent}
                        style={{border: '1px solid green !important'}}
                        suggestions={ownerSuggestions}
                        onSuggestionsFetchRequested={onOwnerSuggestionsFetchRequested}
                        onSuggestionsClearRequested={onOwnerSuggestionsClearRequested}
                        getSuggestionValue={getOwnerSuggestionValue}
                        renderSuggestion={renderOwnerSuggestion}
                        onSuggestionSelected={onOwnerSuggestionSelected}
                        inputProps={inputProps}
                        blurOnSelect
                        class="selectInput"
                        className="selectInput"
                    />
                </div>
                <div className={activeItem === 'blogDate' ? 'searchInputContainer' : 'searchInputContainerNoOutline'}
                     onClick={() => {
                         setActiveItem('blogDate');
                     }}
                >
                    <label htmlFor="owner" id="owner" className='searchLabel'>Blog Date</label>
                    <DatePicker
                        onClick={() => {
                            setActiveItem('blogDate');
                        }}
                        className={'datePicker'}
                        onChange={onChange}
                        startDate={startDate}
                        endDate={endDate}
                        placeholderText="Select Date Range"
                        selectsRange
                        showYearDropdown
                        returnValue="range"
                        dateFormat="MM/dd/yyyy"
                        selected={blogDateFilter}
                    />
                </div>
                <div className='pillsContainer'>
                    <div
                        role={"button"}
                        onClick={() => setJllOnly(!jllOnly)}
                        className={jllOnly ? 'selectedPill' : 'unselectedPill'}
                    >
                        JLL Only
                    </div>
                    <div
                        role={"button"}
                        onClick={() => setTim(!tim)}
                        className={tim ? 'selectedPill' : 'unselectedPill'}
                    >
                        TIM
                    </div>
                </div>
                <SolIconBubble
                    onClick={() => {
                        const submarketValue = submarketFilter.map(item => parseInt(item.id));
                        dispatch(getBlogEntries({
                            searchData: {
                                TIM: tim,
                                JLLOnly: jllOnly,
                                BlogText: blogTextFilter,
                                CreatedBy: null,
                                CreatedByName: ownerFilter,
                                StartDate: startDate ? moment(startDate).format('YYYY-MM-DD') : null,
                                EndDate: endDate ? moment(endDate).format('YYYY-MM-DD') : null,
                                PageSize: 50,
                                PageNo: 1,
                                SubmarketIds: submarketValue
                            },
                            newSearch: true

                        }));
                    }}
                    className='searchIcon'
                    icon="search"
                    size="36"
                />
            </div>

        </div>
    )
}

export default SearchContainerDesktop;