import React, {useState} from 'react';
import PropTypes from 'prop-types';
import './EchelonHomePage.css';
import HeaderDesktop from '../../components/Echelon/Header/HeaderDesktop';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import BlogEntryContainerDesktop from "../../components/Echelon/BlogEntryContainer/BlogEntryContainerDesktop";
import BlogListContainerDesktop from "../../components/Echelon/BlogListContainer/BlogListContainerDesktop";
import {setLocalStorageValue} from "../../utils/tokenManager";

const EchelonHomePage = (props) => {
    const {history} = props;
    const [showAddBlog, setShowAddBlog] = useState(false);
    const [editBlog, setEditBlog] = useState(null);
    const handleEditBlog = (entry) => {
        setEditBlog(entry);
        setShowAddBlog(true);
    }

    React.useEffect(() => {
        setLocalStorageValue('echelonUser','true');
    }, []);

    return (
        <div className='echelonHomepageContainer' >
            <HeaderDesktop
                history={history}
                showJllLogo={false}
            />
            <div className='echelonBlogDetailsContainer'>
                <BlogEntryContainerDesktop
                    setShowAddBlog={setShowAddBlog}
                    showAddBlog={showAddBlog}
                    setEditBlog={setEditBlog}
                    editBlog={editBlog}
                />
                <BlogListContainerDesktop
                    showAddBlog={showAddBlog}
                    handleEditBlog={handleEditBlog}
                    setEditBlog={setEditBlog}
                    editBlog={editBlog}
                />
            </div>


        </div>
    )
}


EchelonHomePage.propTypes = {
    history: PropTypes.object,
};

EchelonHomePage.defaultProps = {
    history: {},
};

function mapStateToProps(state) {
    if (state.company) {
        return {
        };
    }
}

function mapDispatchToProps(dispatch) {
    return {
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(EchelonHomePage);
