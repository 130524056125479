import api from "../../api/index";
import {all, call, put, takeEvery} from "redux-saga/effects";
import {
    GET_BLOG_ENTRIES,
    ADD_BLOG_ENTRY,
    getBlogEntriesSuccess,
    addBlogEntrySuccess, UPDATE_BLOG_ENTRY, updateBlogEntrySuccess, GET_BLOG_OWNERS, getBlogOwnersSuccess,
} from "../actions/Echelon.actions";
import {getBlogOwners, updateBlogEntry} from "../../api/echelon.api";


export function* addBlogEntrySaga(action) {
    try {
        const response = yield call(
            api.echelonApi.addBlogEntry,
            action.payload.newBlogData
        );
        yield put(addBlogEntrySuccess({...response, addedNewBlog: action.payload.addedNewBlog}));
        yield call(getBlogEntriesSaga, {
            payload: {
                searchData: {
                    "TIM": null,
                    "JLLOnly": null,
                    "BlogText": null,
                    "CreatedBy": null,
                    "CreatedByName": null,
                    "StartDate": "",
                    "EndDate": "",
                    "PageSize": 50,
                    "PageNo": 1,
                },
                newSearch: true
            }
        })
    } catch (e) {
        console.log(e);
    }
}

export function* updateBlogEntrySaga(action) {
    try {
        const response = yield call(
            api.echelonApi.updateBlogEntry,
            action.payload.blogData
        );
        yield put(updateBlogEntrySuccess(response));
    } catch (e) {
        console.log(e);
    }
}

export function* getBlogOwnersSaga(action) {
    try {
        const response = yield call(
            api.echelonApi.getBlogOwners,
            action.payload
        );
        yield put(getBlogOwnersSuccess(response));
    } catch (e) {
        console.log(e);
    }
}

export function* getBlogEntriesSaga(action) {
    try {
        const response = yield call(
            api.echelonApi.getBlogEntries,
            action.payload.searchData
        );
        yield put(getBlogEntriesSuccess({...response, newSearch: action.payload.newSearch}));
    } catch (e) {
        console.log(e);
    }
}

export function* watchGetBlogEntries() {
    yield takeEvery(GET_BLOG_ENTRIES, getBlogEntriesSaga);
}

export function* watchAddBlogEntry() {
    yield takeEvery(ADD_BLOG_ENTRY, addBlogEntrySaga);
}

export function* watchUpdateBlogEntry() {
    yield takeEvery(UPDATE_BLOG_ENTRY, updateBlogEntrySaga);
}

export function* watchGetBlogOwners() {
    yield takeEvery(GET_BLOG_OWNERS, getBlogOwnersSaga);
}

export default function* echelonSagas() {
    yield all([
        watchGetBlogEntries(),
        watchAddBlogEntry(),
        watchUpdateBlogEntry(),
        watchGetBlogOwners()
    ]);
}