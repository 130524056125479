import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import './ActivityDialog.css';
import { SolDialog, SolDialogHeader, SolDialogFooter, SolIcon, SolTextField, SolDropdown, SolCheckbox} from '@solstice/sol-react';
import { ToggleSwitch } from '../ToggleSwitch/ToggleSwitch';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Autosuggest from 'react-autosuggest';
import { isEmpty, debounce } from 'lodash';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as UsersActions from "../../store/actions/Users.actions";
import * as ActivityActions from "../../store/actions/Activity.actions";
import moment from 'moment';
import { getUser } from '../../utils/tokenManager';
import * as logic from './logic';
import {autoSuggestScrollToElement} from "../../utils/helpers";
import {Oval} from "react-loader-spinner";
import { setGA4Data } from "../../utils/googleAnalytics";

const ActivityDialogMobile = (props) => {
    const email = getUser()
    const userName = email.substring(0, email.indexOf("@"));
    const roundDateToNearest15Min= (dt, offset = 0)  => {
        dt.setMilliseconds(Math.round(dt.getMilliseconds() / 1000) * 1000);
        dt.setSeconds(Math.round(dt.getSeconds() / 60) * 60);
        dt.setMinutes(Math.round(dt.getMinutes() / 15) * 15);
        dt.setHours(dt.getHours() + offset);
        return dt;
    }
    const initActivity = {
        id: 0,
        subject: '',
        activityType: 'phoneCall',
        dueDate: roundDateToNearest15Min(new Date()),
        nextCallDate: roundDateToNearest15Min(new Date()),
        startDate: roundDateToNearest15Min(new Date()),
        endDate: roundDateToNearest15Min(new Date()),
        regarding: props.regarding,
        regardingId: props.regarding.id,
        regardingTypeId: props.regarding.type,
        isPrivate: false,
        isSubstantialActivity: false,
        doNotSetNextCallDate: true,
        owner: {},
        ownerId: 0,
        description: '',
        addToList: [],
        isCompleted: false,
    }
    const { regarding, SetExitModal, closeActivityModalBtnClicked, users, activityEditId, activityType, activityActions, activity, editMode} = props;
    const [typeSelected, SetTypeSelected] = useState('call');
    const [activityData, setActivityData] = useState(initActivity);
    const [ownerChanged, setOwnerChanged] = useState(false);
    const [ownerSuggestions, setOwnerSuggestions] = useState([]);
    const [ownerValue, setOwnerValue] = useState(userName);
    const [errorArr, setErrorArr] = useState([]);
    const [showLoader, setShowLoader] = useState(false);

    useEffect(() => {
        if(activityEditId > 0) {
            activityActions.getActivityById({id: activityEditId, type: activityType })
        } else {
            setActivityData(initActivity);
        }
    }, [activityEditId]);

    useEffect(() => {
        if(!isEmpty(activity) && activityEditId > 0) {
            const _ownerId = activity.owner.id ? activity.owner.id : null;
            const _regId = activity.regarding.id ? activity.regarding.id : null;
            const _regType = activity.regarding.type ? activity.regarding.type : null;
            const _addToList =  !activity.isCompleted ? [{ id: 1 }] : [];

            activity.ownerId = _ownerId;
            activity.regardingId = _regId;
            activity.regardingTypeId = _regType;
            activity.addToList = _addToList;

            setActivityData(activity);
            const owner = activity.owner.displayName ? activity.owner.displayName : '';
            setOwnerValue(owner);
            const type = activity.activityType === 'appointment' ? 'meeting' : 'call';
            SetTypeSelected(type);
        }
    }, [activity]);
    useEffect(() => {

    }, [regarding])
    useEffect(() => {
        if (!isEmpty(users)) {

            if (users.length === 1 && ownerSuggestions.length === 0 && ownerValue === users[0].fullName) {
                //Do Nothing
            } else {
                const act = {...activityData};
                setOwnerSuggestions(users);
                if(ownerChanged === false && users.length === 1 && users[0] && activityData.ownerId === 0) {
                    act['owner'] = {id: users[0].id, displayName: users[0].fullName};
                    act['ownerId'] = users[0].id;
                    setActivityData(act);
                    setOwnerValue(users[0].fullName);
                }
            }
        }
    }, [users, ownerValue])
    React.useEffect(() => {
        const { usersActions, ownerSuggestions} = props
        if(!isEmpty(ownerValue) && ownerValue.length >= 3) {
            getUsersDebounced(ownerValue, usersActions);
        }

    }, [ownerValue]);
    const onModalClose = () => {
        SetExitModal(true);
    }
    const getUsersDebounced = useCallback(
        debounce((ownerValue, usersActions) => {usersActions.getUsers({ value: ownerValue })}, 300), []
    )
    const setActivityValue = (val, field) => {
        const act = {...activityData};
        act[field] = val;
        setActivityData(act);
    }
    const onValueChange = (e, field) => {
        const act = {...activityData};
        act[field] = e.detail;
        clearErrorForField(field);
        setActivityData(act);
    }
    const onTextAreaValueChange = (e, field) => {
        const act = {...activityData};
        act[field] = e.target.value;
        setActivityData(act);
    }
    const onToggleValueChange = (field) => {
        const act = {...activityData};
        act[field] = !act[field];
        setActivityData(act);
    }
    const onCheckValueChange = (field) => {
        const act = {...activityData};
        act[field] = !act[field];
        setActivityData(act);
    }

    const onDatePickerChange = (val, dtField) => {
        const act = {...activityData};
        act['endDate'] = val;
        act[dtField] = val;
        setActivityData(act);
    }
    const getOwnerSuggestions = value => {
        if (!isEmpty(value) && value.length >= 3) {
            const inputValue = value.trim().toLowerCase();
            const inputLength = inputValue.length;

            return inputLength === 0 && !isEmpty(ownerSuggestions) ? [] : ownerSuggestions.filter(o =>
                o.fullName.toLowerCase().slice(0, inputLength) === inputValue
            );
        } else {
            return [];
        }
    };
    const getOwnerSuggestionValue = suggestion => suggestion.fullName;
    const renderOwnerSuggestion = suggestion => (
        <div className="dvSuggest">
            {suggestion.fullName}
        </div>
    );
    const onOwnerChange = (event, { newValue }) => {
        if(newValue.length <= 3){
            const act = {...activityData};
            act['owner'] = {};
            act['ownerId'] = 0;
            setOwnerChanged(true);
            setActivityData(act);
        }
        setOwnerValue(newValue);
    };
    const onOwnerSuggestionsFetchRequested = ({ value }) => {
        const suggestions =  getOwnerSuggestions(value);
        setOwnerSuggestions(suggestions);
    };
    const onOwnerSuggestionsClearRequested = () => {
        const suggestions = getOwnerSuggestions([]);
        setOwnerSuggestions(suggestions);
    };
    const onOwnerSuggestionSelected = (event, { suggestion, suggestionValue, index, method }) => {
        event.preventDefault();
        const selection = suggestion;
        const act = {...activityData};
        act['owner'] = {id: selection.id, displayName: selection.fullName};
        act['ownerId'] = selection.id;

        clearErrorForField('owner');
        setActivityData(act);
        setOwnerChanged(true);
        onOwnerSuggestionsClearRequested();
    }
    const inputProps = {
        placeholder: 'Select owner',
        value: ownerValue,
        onChange: onOwnerChange,
        onClick: () => {
            autoSuggestScrollToElement('owner');
     }
    };
    const renderInputComponent = inputProps => (
        <div>
            <input {...inputProps} />
            <sol-icon
                size="24"
                style={{display: !ownerValue && 'none', float: 'right', margin: '-32px 5px 0px 0px'}}
                icon="close"
                onClick={() => { setOwnerValue('');}}
            />
        </div>
    );
    const clearErrorForField = (name) => {
        const errArr = [...errorArr];
        const index = errArr.indexOf(name);
        if (index > -1) {
            errArr.splice(index, 1);
        }
        setErrorArr(errArr);
    }
    const handleAddEditActivity = async (activityText) => {
        const { regarding } = props;
        if (activityText === 'add') {
            setGA4Data('ActivityCreated', { companyID: regarding.id });
        } else {
            setGA4Data('ActivityModified', { companyID: regarding.id });
        }
        activityActions.addEditActivity({activityData, cb: (flag) =>  {
            setShowLoader(flag);
            if(!flag) {
                closeActivityModalBtnClicked();
            }
            }});
    }
    const validateForm = () => {
        const act = {...activityData}
        let blnValid = true;
        const arrErr = [...errorArr]

        if(!act.subject || act.subject === '') {
            arrErr.push('subject');
            blnValid = false;
        }
        if(!act.regarding || act.regarding === {}) {
            arrErr.push('regarding');
            blnValid = false;
        }
        if(!act.owner || !(act.owner.id > 0) ) {
            arrErr.push('owner');
            blnValid = false;
        }

        if (act.activityType === 'phoneCall') {
            if (!act.dueDate || act.dueDate === '') {
                blnValid = false;
            }
        } else if (act.activityType === 'appointment') {
            if (!act.startDate || act.startDate === '') {
                blnValid = false;
            }
            const dtStart = new Date(act.startDate);
        }

        if(blnValid === true) {
            setErrorArr([]);
        } else {
            setErrorArr(arrErr);
        }

        return blnValid
    }
    return (
        <div className='ActivityDialog' id='ActivityDialog'>
            <div className='sol-dialog__backdrop'/>
            {showLoader && <div style={{
                display: 'flex',
                justifyContent: 'center'
            }}> <Oval
                visible={true}
                height="40"
                width="40"
                color="#000000"
                secondaryColor={"gray"}
                ariaLabel="oval-loading"
                wrapperStyle={{zIndex: 1000000}}
                wrapperClass=""
            />
            </div>}
            {!showLoader && <SolDialog
                hasBackdrop={false}
                className="activityModal"
                floatingPosition="center"
                onSol-close={() => { onModalClose()}}
            >
                <SolDialogHeader
                    slot="header"
                    label={editMode === "edit" ? "Edit Activity" : "Add Activity"}
                >
                    {editMode === "edit"
                        ? (<SolIcon slot="icon" icon="edit" />)
                        : (<SolIcon slot="icon" icon="add_circle" />)
                    }
                </SolDialogHeader>

                <div slot="body">
                <div className='ActivityHeaderButtons'>
                    <div className='SearchHeaderButton'
                         style={{
                            cursor: activityData && activityData.id > 0 && activityData.activityType === 'appointment' ? 'not-allowed' : 'pointer',
                            background: typeSelected === 'call' && '#01151C',
                            color: typeSelected === 'call' && '#FFFFFF'
                        }}

                         onClick={(e) => {
                            if (activityData && activityData.id === 0) {
                                SetTypeSelected('call');
                                setActivityValue('phoneCall', 'activityType');
                            }
                         }}>
                        <sol-icon icon='phone_forwarded'
                        style={{ color: typeSelected === 'call' ? '#32A4CA' : '#01151C' }}
                        />Call
                    </div>
                    <div className='ActivityHeaderButton'
                         style={{
                            cursor: activityData && activityData.id > 0 && activityData.activityType === 'phoneCall' ? 'not-allowed' : 'pointer',
                            background: typeSelected === 'meeting' && '#01151C',
                            color: typeSelected === 'meeting' && '#FFFFFF'
                         }}
                         onClick={(e) => {
                            if (activityData && activityData.id === 0) {
                                SetTypeSelected('meeting')
                                setActivityValue('appointment', 'activityType');
                            }
                        }}>
                        <sol-icon icon='handshake'
                         style={{ color: typeSelected === 'meeting' ? '#32A4CA' : '#01151C' }}
                        />Meeting
                    </div>
                </div>
                <div className='background-toggle-container'>
                <ToggleSwitch
                    title='Private'
                    checked={activityData && activityData.isPrivate ? activityData.isPrivate : false}
                    onToggleChange={() => {
                       onToggleValueChange('isPrivate')
                    }}>
                </ToggleSwitch>
                </div>
                <div className="fieldSpacer" />
                <SolTextField
                    value={activityData && activityData.subject ? activityData.subject : ''}
                    state={errorArr && errorArr.includes('subject') === true ? "error" : "neutral"}
                    onSol-input={e => {onValueChange(e, 'subject')}}
                    label="Subject" placeholder="Enter subject" class="light"
                    size="undefined" helper-label=""
                    error-label="" required="true" type="undefined" is-opaque="undefined"
                >
                </SolTextField>
                <div className="fieldSpacer" />
                {typeSelected === 'call'
                ?
                    <DatePicker
                    showYearDropdown
                    dateFormat="MM/dd/yyyy"
                    selected={activityData && activityData.dueDate ? new Date(activityData.dueDate) : ''}
                    onChange={(date) => onDatePickerChange(date, 'dueDate')}
                    customInput={
                        <span>
                        <SolTextField
                        value={activityData && activityData.dueDate ? moment(activityData.dueDate).format('MMM DD, YYYY') : ''}
                        label="Date" placeholder="Select Date" class="light"
                        onMouseDown={e => {
                            e.preventDefault();
                        }}
                        size="undefined" state="neutral" helper-label=""
                        error-label="" required="true" type="undefined" is-opaque="undefined"
                    /></span>
                        }
                    />
                :
                    <div className="dvStartDate">
                        <DatePicker
                            showYearDropdown
                            dateFormat="MM/dd/yyyy"
                            selected={activityData && activityData.startDate ? new Date(activityData.startDate) : ''}
                            onChange={(date) => onDatePickerChange(date, 'startDate')}
                            customInput={
                                <span>
                                <SolTextField
                                value={activityData && activityData.startDate ? moment(activityData.startDate).format('MMM DD, YYYY') : ''}
                                label="Date" placeholder="Select Date" class="light"
                                size="undefined" helper-label="" state="neutral"
                                onMouseDown={e => {
                                    e.preventDefault();
                                }}
                                error-label="" required={true} type="undefined" is-opaque="undefined"
                            /></span>
                        }
                            />
                        <div className="fieldSpacer" />
                    </div>
                }
                <div className="fieldSpacer" />
                <SolTextField
                    value={activityData && activityData.regarding && activityData.regarding.displayName ? activityData.regarding.displayName : null }
                    label="Regarding" placeholder="Company or Contact name"
                    class="light" size="undefined" state="neutral"
                    helper-label="" error-label="" required="true"
                    readonly
                    type="undefined" is-opaque="undefined"
                ></SolTextField>
                <div className="fieldSpacer" />
                <label for="owner" id="owner" className='frmLabel'>Owner</label><span className="redAsterisk">*</span>
                  <Autosuggest
                    theme={{
                        input: errorArr && errorArr.includes('owner') === true ? "auto-suggest-input-error" : ownerValue ? "auto-suggest-input-padding" : "auto-suggest-input",
                        suggestionsContainer: 'auto-suggest-container',
                        suggestion: 'auto-suggest-suggestion',
                        suggestionHighlighted: 'suggestion-highlighted'
                    }}
                    renderInputComponent={renderInputComponent}
                    style={{border: '1px solid green !important'}}
                    suggestions={ownerSuggestions}
                    onSuggestionsFetchRequested={onOwnerSuggestionsFetchRequested}
                    onSuggestionsClearRequested={onOwnerSuggestionsClearRequested}
                    getSuggestionValue={getOwnerSuggestionValue}
                    renderSuggestion={renderOwnerSuggestion}
                    onSuggestionSelected={onOwnerSuggestionSelected}
                    inputProps={inputProps}
                    blurOnSelect
                    class="selectInput"
                    className="selectInput"
                />
                <div className="fieldSpacer" />
                <div className="dvNextCallDate" style={{ display: typeSelected === 'call' ? 'block' : 'none' }}>
                    <div className='background-toggle-container'>
                    <ToggleSwitch
                        title='Next call date'
                        checked={activityData && activityData.doNotSetNextCallDate === false ? true : false}
                        onToggleChange={() => {
                            onToggleValueChange('doNotSetNextCallDate')
                        }}>
                    </ToggleSwitch>
                    </div>
                    <span style={{display: activityData && activityData.doNotSetNextCallDate === false ? 'unset' : 'none'}}>
                    <DatePicker
                    showYearDropdown
                    dateFormat="MM/dd/yyyy"
                    disabled={activityData && activityData.doNotSetNextCallDate === false ? false : true}
                    selected={activityData && activityData.nextCallDate ? new Date(activityData.nextCallDate) : ''}
                    onChange={(date) => onDatePickerChange(date, 'nextCallDate')}
                    customInput={
                        <span>
                            <SolTextField
                            value={activityData && activityData.nextCallDate ? moment(activityData.nextCallDate).format('MMM DD, YYYY hh:mm A') : ''}
                            placeholder="Select Date" class="light" label="Date"
                            size="undefined" state="neutral" helper-label=""
                            onMouseDown={e => {
                                e.preventDefault();
                            }}
                            error-label="" required={true} type="undefined" is-opaque="undefined"
                            />
                        </span>
                }
                    />
                </span>
                </div>
                <div className="fieldSpacer" />
                <label for="description" className='frmLabel'>Description</label>
                <textarea id="description" name="description"
                    maxLength={2000}
                    placeholder='Enter the description'
                    value={activityData && activityData.description ? activityData.description : null}
                    onChange={e => {onTextAreaValueChange(e, 'description')}}
                    rows="4" cols="46" className="descTextArea"
                ></textarea>
                <div className="fieldSpacer" />
                <SolCheckbox
                    checked={activityData && activityData.isSubstantialActivity === true ? true : false}
                    onSol-checked={e => onCheckValueChange('isSubstantialActivity')}
                    className="chkBox"></SolCheckbox><span className='checkboxLabel'>Substantial activity</span>
                <div className='substantialText'>
                    For activity to count toward protection, it must be objective, substantive,
                    concrete and accurate. It should have thoughtful information on names of people
                    with whom you have met, actionable next steps, pursuit plan, etc.
                    Entries like “working on them” or “left a message” are not considered substantive.”
                </div>
                <div className="fieldSpacer" />
                <SolCheckbox
                    checked={activityData && activityData.addToList && activityData.addToList[0] && activityData.addToList[0].id === 1 ? true : false}
                    onSol-checked={e => {
                        // onCheckValueChange(e, 'isSubstantialActivity')
                        const act = {...activityData};
                        if (e.detail === false) {
                            act['addToList'] = [];
                            act['isCompleted'] = true;
                        } else {
                            act['addToList'] = [{id: 1, description: "Add to To-Do List"}];
                            act['isCompleted'] = false;
                        }


                        setActivityData(act);
                    }}
                    className="chkBox"></SolCheckbox> <span className='checkboxLabel'>Add to To-Do List</span>
                <div className="fieldSpacer" />
                {errorArr.length > 0 ? (<span className='errorText'>* Fix Errors Above</span>) : ''}
                <div className='ActivityFooterButtons'>
                        {editMode === 'add'
                        ? (<div className='ActivityFooterButton'
                            style={{
                                cursor: errorArr && errorArr.length > 0  ? 'not-allowed' : 'pointer',
                                background: errorArr && errorArr.length === 0 && '#01151C',
                                color: errorArr && errorArr.length === 0 && '#FFFFFF'
                            }}
                            onClick={(e) => {
                                if(validateForm() === true) {
                                    handleAddEditActivity('add');
                                }
                            }}>
                            <sol-icon icon='check_circle'
                                style={{
                                    color: errorArr && errorArr.length > 0 ? '#01151C' : '#32A4CA'
                                }}
                            />Create Activity
                        </div>)
                        : (<div className='ActivityFooterButton'
                        style={{
                            cursor: errorArr && errorArr.length > 0  ? 'not-allowed' : 'pointer',
                            background: errorArr && errorArr.length === 0 && '#01151C',
                            color: errorArr && errorArr.length === 0 && '#FFFFFF'
                        }}

                            onClick={(e) => {
                                if(validateForm() === true) {
                                    handleAddEditActivity('edit');
                                }
                            }}>
                            <sol-icon icon='save'
                                style={{
                                    color: errorArr && errorArr.length > 0 ? '#01151C' : '#32A4CA'
                                }}
                            />Save
                            </div>
                            )}
                    </div>
                </div>
                {/* <SolDialogFooter slot="footer">
                </SolDialogFooter> */}
            </SolDialog>}
        </div>
    )
}

ActivityDialogMobile.propTypes = {
    regarding: PropTypes.object,
    SetExitModal: PropTypes.func,
    closeActivityModalBtnClicked: PropTypes.func,
    users: PropTypes.array,
    activityEditId: PropTypes.number,
    activityType: PropTypes.string,
    activityActions: PropTypes.object,
    activity: PropTypes.object,
    editMode: PropTypes.string,
};

ActivityDialogMobile.defaultProps = {
    regarding: {},
    SetExitModal: () => {},
    closeActivityModalBtnClicked: () => {},
    users: [],
    activityEditId: 0,
    activityType: '',
    activityActions: {},
    activity: {},
    editMode: '',
};

function mapStateToProps(state) {
    return {
        users: state.users.users,
        activity: state.activity.activity,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        usersActions: bindActionCreators(UsersActions, dispatch),
        activityActions: bindActionCreators(ActivityActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ActivityDialogMobile);
