import React, {useState} from 'react';
import './BlogEntryContainer.css';
import {isEmpty} from "lodash";
import {SolButton, SolCheckbox, SolDropdown, SolIcon, SolIconBubble} from "@solstice/sol-react";
import {useDispatch, useSelector} from "react-redux";
import {lookup} from "../../../store/actions/Lookup.actions";
import {addBlogEntry, updateBlogEntry} from "../../../store/actions/Echelon.actions";
import addIcon from "../../../assets/addIcon.svg";
import {Collapse} from 'react-collapse';
import SearchContainerDesktop from "../SearchContainer/SearchContainerDesktop";


const BlogEntryContainerDesktop = (props) => {
    const [submarketSuggestions, setSubmarketSuggestions] = useState([]);
    const [submarketValue, setSubmarketValue] = useState([]);
    const [tim, setTim] = useState(false);
    const [jllOnly, setJllOnly] = useState(false);
    const [blogText, setBlogText] = useState('');
    const dispatch = useDispatch();
    const submarkets = useSelector(state => state.lookup.lookup);
    const {editBlog} = props;
    const {
        setShowAddBlog,
        showAddBlog
    } = props;
    React.useEffect(() => {
        dispatch(lookup('GetBlogSubMarkets'));
    }, []);

    React.useEffect(() => {
        if (editBlog) {
            setSubmarketValue(editBlog.subMarkets.map(item => item.displayName));
            setTim(editBlog.tim);
            setJllOnly(editBlog.jllOnly);
            setBlogText(editBlog.blogText);
        }
    }, [editBlog]);

    React.useEffect(() => {
        let tempSubmarkets = [];
        tempSubmarkets = submarkets.map(item => {
            return {label: item.value, ...item}
        });
        setSubmarketSuggestions(tempSubmarkets)
    }, [submarkets])

    const resetBlogEntry = () => {
        const { setEditBlog } = props;
        setSubmarketValue([]);
        setBlogText('');
        setTim(false);
        setJllOnly(false);
        setEditBlog(null);
    }
    const handleAddBlogEntry = () => {
        let submarketsIds = submarkets.filter(item => submarketValue.includes(item.value) === true).map(item => parseInt(item.id));
       if(editBlog) {
       dispatch(updateBlogEntry({
           blogData: {
               blogEntryId: editBlog.blogEntryId,
                blogText: blogText,
                jllOnly: jllOnly,
                tim: tim,
               createdBy: editBlog.createdBy ? editBlog.createdBy : null,
               createdByName: editBlog.createdBy ? null : editBlog.createdByName,
                submarketIds: submarketsIds,
           },
           addedNewBlog: true
       }))
       } else {
           dispatch(addBlogEntry({
               newBlogData: {
                   "blogText": blogText,
                   "jllOnly": jllOnly,
                   "tim": tim,
                   "submarketIds": submarketsIds,
               }
           }));
       }

        resetBlogEntry();
        setShowAddBlog(false);

    }

    return (
        <div>
            <SearchContainerDesktop />
            {!showAddBlog &&  <div className={'blogEntryHeader'}>
                {!showAddBlog && <img
                    onClick={() => {
                        setShowAddBlog(true);
                    }}
                    className='addNewBlogIcon'
                    src={addIcon}
                /> }
                <div
                    className={'addNewText'}
                    onClick={() => {
                    setShowAddBlog(true);
                }}>Add New</div>
            </div>}
            <Collapse isOpened={showAddBlog}>
                <div className={'blogEntryOutline'}>
                    <div className='blogEntryContainer'>
                        <div className='blogEntryHeaderContainer'>
                            <SolDropdown
                                isMultiSelect
                                isOpaque
                                multiselectionDisplay='text'
                                label="Submarket"
                                size={'small'}
                                value={submarketValue}
                                onUpdate-value={e => {
                                    setSubmarketValue(e.detail);
                                }}
                                options={submarketSuggestions}
                            />
                        </div>
                        <div className='blogCheckboxPanel'>
                            <div>
                                <SolCheckbox
                                    checked={jllOnly}
                                    onSol-checked={e => setJllOnly(!jllOnly)}
                                    className="chkBox"></SolCheckbox><span className='checkBoxLabel'>JLL only</span>
                            </div>
                            <div>
                                <SolCheckbox
                                    checked={tim}
                                    name="TIM"
                                    onSol-checked={e => setTim(!tim)}
                                    className="chkBox"></SolCheckbox>
                                <span className='checkBoxLabel'>Tim</span>
                            </div>
                        </div>
                    </div>
                    <textarea
                        id="blogEntryTextArea"
                        maxLength={2000}
                        value={blogText}
                        placeholder='Enter blog text...'
                        onChange={e => {
                            setBlogText(e.target.value)
                        }}
                        rows="3"
                        className="echelonBlogTextArea"
                    ></textarea>
                    <div className={'blogEntryFooter'}>
                        <div className={'footerButton'}>
                            <SolButton
                                label={'Cancel'}
                                variant={'secondary'}
                                onSol-click={() => {
                                    resetBlogEntry();
                                    setShowAddBlog(false);
                                }}
                                size={'medium'}
                            />
                        </div>
                        <div>
                            <SolButton
                                label={editBlog ? 'Save' : 'Add Blog'}
                                disabled={submarketValue.length <= 0 || isEmpty(blogText)}
                                onSol-click={() => handleAddBlogEntry()}
                                size={'medium'}
                                appendIcon={'chat_add_on'}
                            />
                        </div>
                    </div>
                </div>
            </Collapse>
        </div>
    )
}

export default BlogEntryContainerDesktop;