import React from 'react';
import {deleteLocalStorageValue, getUser} from "../../../utils/tokenManager";
import aeroConfig from "../../../config/aeroConfig";
import './Header.css';

const HeaderDesktop = (props) => {

    const user = getUser();
    const oktalogout = aeroConfig.oktalogout || process.env.REACT_APP_OKTALOGOUT;
    const [showLogout, SetShowLogout] = React.useState(false);

    React.useEffect(() => {
        window.addEventListener('click', (e) => {
            if (e?.target?.id?.includes('user') || e?.target?.parentElement?.id.includes('user') || e?.target?.id.includes('logoutContainer')) {
                SetShowLogout(!showLogout);
            } else {
                SetShowLogout(false);
            }
        });
    }, [showLogout]);

    return (
        <div className='echelonHeader' id='MainHeader'>
            <div>
                <img
                    className='headerLogo'
                    src={'https://echelon.jll.com/assets/mainsite/img/user_dashboard/brand.png'}
                    alt="Echelon"
                />
            </div>

            <div
                className='echelonUserSection'
                id='userSection'
                role="button"
            >
                <div id="userCircle" style={{display: "flex"}}>
                    <div className='headerUserCircle'>
                        {user?.[0]}
                        {user?.split('.')[1][0]}
                    </div>
                    <div
                        style={{margin: '10px 10px 0 5px', color: '#FFF'}}
                        className='sol-icon-keyboard_arrow_down'
                    />
                </div>
                <a
                    id={'logoutContainer'}
                    href={oktalogout}
                    onClick={() => {
                        deleteLocalStorageValue('okta-token-storage');
                    }}
                    style={{display: !showLogout && 'none'}}
                >
                    <div className='LogoutTooltip'>
                        Log out
                        <div className='sol-icon-logout'/>
                        <div className="LogoutTooltipArrow"/>
                    </div>
                </a>
            </div>
        </div>
    )
}

export default HeaderDesktop;