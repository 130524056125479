import React from 'react';
import './BlogListContaier.css';
import InfiniteScroll from "../../InfiniteScroll/InfiniteScrollComponent";
import {useDispatch, useSelector} from "react-redux";
import {getBlogEntries} from "../../../store/actions/Echelon.actions";
import moment from 'moment';
import {SolIcon} from "@solstice/sol-react";
import {getUser} from "../../../utils/tokenManager";

const BlogListContainerDesktop = (props) => {

    const dispatch = useDispatch();
    const user = getUser();

    React.useEffect(() => {
        dispatch(getBlogEntries({
            searchData: {
                TIM: null,
                JLLOnly: null,
                BlogText: null,
                CreatedBy: null,
                CreatedByName: null,
                StartDate: "",
                EndDate: "",
                PageSize: 50,
                PageNo: 1
            },
            newSearch: true,

        }));
    }, []);

    const blogEntries = useSelector(state => state.echelon.blogEntries);

    const handleNext = () => {
        dispatch(getBlogEntries({
            searchData: {
                TIM: null,
                JLLOnly: null,
                BlogText: null,
                CreatedBy: null,
                CreatedByName: null,
                StartDate: "",
                EndDate: "",
                PageSize: 50,
                PageNo: parseInt(blogEntries?.allBlogs?.length / 50) + 1
            },
            newSearch: false,

        }));
    }
    const { showAddBlog } = props;
    const getSubMarketsList = (entry) => {
        let submarketList = '';
        entry?.subMarkets?.map((submarket, index) => {
        submarketList = `${submarketList} ${submarket.displayName}`
    });
    return submarketList;
    }

    const isEditableUser = (entry) => {
        let userFirstName = (user.split('@')?.[0])?.split('.')?.[0];
        let userLastName = (user.split('@')?.[0])?.split('.')?.[0];
        return (entry.createdByName.includes(userFirstName) && entry.createdByName.includes(userLastName));
    }

    const getBlogEntryUserInitials = (entry) => {
        if (entry?.createdByName.includes(',')) {
            return `${entry?.createdByName?.split(',')?.[1]?.[1] || ''}${entry?.createdByName?.split(',')?.[0]?.[0] || ''}`;
        } else {
            return `${entry?.createdByName?.split(' ')?.[1]?.[0] || ''}${entry?.createdByName?.split(' ')?.[0]?.[0] || ''}`;

        }
    }
    const { editBlog, setEditBlog } = props;
    return (
        <div style={{height: showAddBlog ? 'calc(100vh - 380px)': '75vh', overflowY: 'scroll', overflowX: 'hidden'}} className={'scrollContainer'}>
            {blogEntries?.totalBlogsCount && <InfiniteScroll
                scrollThreshold={'200px'}
                dataLength={blogEntries?.totalBlogsCount || 0}
                hasMore={blogEntries?.totalBlogsCount > blogEntries?.allBlogs?.length}
                loader={<h4 className="resultsMessage">Loading...</h4>}
                load={handleNext}
                endMessage={
                    <p className="resultsMessage">
                        <b>No More Results</b>
                    </p>
                }
            >
                {blogEntries?.allBlogs?.filter(item=> item.blogEntryId !== editBlog?.blogEntryId).map((entry, index) => {
                    return <div id={entry?.blogEntryId}>
                        <div className={'blogCardMainContainer'}>
                            <div style={{width: '6%', margin: '0 10px 10px 0'}}>
                                <div className="companyContactCircle">{getBlogEntryUserInitials(entry)}</div>
                            </div>
                            <div style={{width: '92%'}}>
                                <div className={'blogCardHeader'}>
                                    {entry?.subMarkets?.map((submarket, index) => {
                                        return <div className='subMarketPill'> {submarket.displayName}</div>
                                    })}
                                </div>
                                <div className={'blogCardBody'}>
                                    {entry?.blogText}
                                </div>
                                <div className={'blogCardFooter'}>
                                    <div className={'blogCardFooterText'}>
                                        {`Posted by `}<b>{entry?.createdByName}</b> on {moment(entry?.createdOn).format('MM/DD/YYYY')}
                                    </div>
                                    <div style={{display: 'flex'}}>
                                        {entry.jllOnly && <div className='jllOnlyPill'>
                                            JLL Only
                                        </div>}
                                        {entry.tim && <div className='timPill'>
                                            TIM
                                        </div>}
                                    </div>
                                </div>
                            </div>
                            <div style={{width: '2%'}}>
                            {isEditableUser(entry) && <div onClick={() => {
                                props.handleEditBlog(entry);
                            }}>
                                <SolIcon icon="edit" size="19"/>
                            </div>}
                            </div>
                        </div>
                    </div>

                })}
            </InfiniteScroll>}
        </div>
    )
}

export default BlogListContainerDesktop;