import React, {useState} from 'react';
import PropTypes from 'prop-types';
import SearchContainerMobile from '../../components/SearchContainer/SearchContainerMobile';
import './HomePage.css';
import HeaderMobile from '../../components/Header/HeaderMobile';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import * as SearchActions from '../../store/actions/Search.actions';

const HomePage = (props) => {

    const { history } = props;

    React.useEffect(() => {
        const isEchelonUser = localStorage.getItem('echelonUser');
        if(isEchelonUser === 'true') {
            history.push('/echelon');
        }
    },[]);

    return (
        <div className='HomePage' id='HomePage'>
            <HeaderMobile
                history={history}
                showJllLogo
            />
            <SearchContainerMobile
                history={history}
                searchActions={props.searchActions}
                searchDetails={props.searchDetails}
            />
        </div>
    )
}

HomePage.propTypes = {
    history: PropTypes.object,
    searchActions: PropTypes.object,
    searchDetails: PropTypes.object,
};

HomePage.defaultProps = {
    history: {},
    searchActions: {},
    searchDetails: {},
};

function mapStateToProps(state) {
    if (state.search) {
        return {
            searchDetails: state.search,
        };
    }
}

function mapDispatchToProps(dispatch) {
    return {
        searchActions: bindActionCreators(SearchActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
